var submitAddToPath = function() {
	var destID = $('#addToPathForm').find('.destID').val(),
		addDestDate = $('#addDestToPathDate').val(),
		pathID = $('#choosePathID').val();
/*
	$.ajax({
		url: '/mypath/api/dests.cfm',
		type: 'POST',
		data: {action: 'addDestToPath',pathid:pathID,destid:destID,addDestDate:addDestDate}
	})
*/
	MyPath.paths.addDest(pathID,destID,addDestDate).done(function(data) {
		var json = $.parseJSON(data);
		prependAlert($('#addToPathForm'),json.status,json.message);
	})
	.fail(logAjaxErr);
	return false;
};
var favoriteDest = function(destid,thisLink) {
	var btn = $(thisLink),
		icon = $(thisLink).find(".fa"),
		letContinue;
	
	if (!btn.hasClass('isFavorite')) {
		letContinue = false;
		// then do ajax to make it a favorite!
		MyPath.dests.markFavorite(destid,'1').done(function(data) {
			// make it a favorite
			btn.addClass('isFavorite');
			
			// full heart
			icon.removeClass("fa-heart-o").addClass("fa-heart");
			
			// now link to "favorites" page
			btn.find(".text").text("View Favorites");
		})
		.fail(logAjaxErr);
	} else {
		// else let them go to the favorites page
		letContinue = true;
	}
	
	return letContinue;
};