setupDropdowns = function() {
	$('#factsNav').find('a.dropdown-toggle').on("click",function(e) {
		$(this).next('.dropdown-menu').toggle();
		e.preventDefault();
		e.stopPropagation();
		return false;
	});
	$(window).on('click', function() {
		$('#factsNav').find('.dropdown-menu').hide();
	});
};
(function($) {
	var factBar = $("#featuredHistory"),
		cookieName = "cookie_featuredHistory",
		expireDays = 1;
	
	// do the facts page tabbed nav
	setupDropdowns();
	
	// check to see if they closed the featured fact bar.
	if (typeof Cookies === "function") {
		if (Cookies.get(cookieName) &&
			Cookies.getJSON(cookieName).closed) {
			// then close the bar!
			factBar.removeClass("in");
		}
	}
	
	// when they close the featured history bar, REMEMBER that it should stay closed!
	factBar.find(".featuredHistory__close").on("click", function() {
		if (typeof Cookies === "function") {
			// store "they closed today's fact!" in a cookie.
			Cookies.set(cookieName, {closed: true} , { expires: expireDays});
		}
	});
})(jQuery);