// start service worker for better caching.
if ('serviceWorker' in navigator) {
/*
	// @cite https://developers.google.com/web/fundamentals/primers/service-workers/
	window.addEventListener('load', function() {
		navigator.serviceWorker.register('/sw.js').then(function(registration) {
			// Registration was successful
			console.log('ServiceWorker registration successful with scope: ', registration.scope);
		}, function(err) {
			// registration failed :(
			console.log('ServiceWorker registration failed: ', err);
		});
	});
*/
}
/*
 * start checking for online/offline-ness.
*/
Offliner = function() {
	var self = {
		element: document.getElementById("app-notif"),
		bannerHTML: function(icon,title,level) {
			/*
			 * given an icon and title, return HTML for an app notification/message.
			 * 
			 * @param {string} icon - class name of a font awesome icon.
			 * @param {string} title - what should the notifications say?
			 * @param {string} level - danger / warning / success
			 * @returns {string} - HTML string.
			 * @author Michael Born
			 * @date 2/12/2018
			*/
			if (!level) { level = "danger"; }
			return '<div class="alert alert-' + level + '"><div class="media"><div class="media-left"><span class="fa fa-2x ' + icon + ' media-object"></span></div><div class="media-body"><span class="media-heading h5">'  + title + '</span></div></div></div>';
		},
		showOfflineBanner: function() {//console.log("showOfflineBanner():",arguments);
			/*
			 * build and show the offline banner notification
			 * 
			 * @param void
			 * @returns void
			 * @author Michael Born
			 * @date 2/12/2018
			*/
			self.element.innerHTML = self.bannerHTML("fa-wifi","Browsing Offline");
			
			// show the banner the entire time they are offline.
			self.showBanner();
		},
		showOnlineBanner: function() {//console.log("showOnlineBanner():",arguments);
			/*
			 * build and show the "back online!" banner for a limited amount of time.
			 * 
			 * @param void
			 * @returns void
			 * @author Michael Born
			 * @date 2/12/2018
			*/
			self.element.innerHTML = self.bannerHTML("fa-wifi","Back online!","success");
			
			// hide the banner after 2 seconds of being back online.
			window.setTimeout(self.hideBanner,2000);
		},
		showBanner: function() {
			/*
			 * hide the banner using Bootstrap's .collapse.in functionality.
			 * 
			 * @param void
			 * @returns void
			 * @author Michael Born
			 * @date 2/12/2018
			*/
			self.element.classList.add("in");
		},
		hideBanner: function() {
			/*
			 * hide the banner using Bootstrap's .collapse functionality.
			 * 
			 * @param void
			 * @returns void
			 * @author Michael Born
			 * @date 2/12/2018
			*/
			self.element.classList.remove("in");
		},
		init: function() {
			/*
			 * begin checking for offline/online events so we can show a warning if the user is offline.
			 * 
			 * @param void
			 * @returns void
			 * @author Michael Born
			 * @date 2/12/2018
			*/
			window.addEventListener('offline', self.showOfflineBanner);
			window.addEventListener('online', self.showOnlineBanner);
		}
	};
	return self;
};
offlineBanner = Offliner();
offlineBanner.init();