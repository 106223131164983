(function($) {
	/*======================================================
	** 			|     OFF-CANVAS MENU       |  		  **
	** Click menu button to "slide" the menu in from the left.
	======================================================== */
	var pageCanvas = $(".menuShiftOnOpen"),
		_root = $("html"),
		_toggle = $(".mainMenu__toggle"),
		_icon = $("#mainMenu").find(".navbar-toggle>.fa");
	
	_toggle.off("click").on("click",function(e) {
		_root.toggleClass('menuOpen');
		
		if ( _root.hasClass("menuOpen") ) {
			// then change to "x" icon
			_icon.addClass("fa-times").removeClass("fa-navicon");
		} else {
			// else change back to "navbars" icon
			_icon.addClass("fa-navicon").removeClass("fa-times");
		}
		e.preventDefault();
		e.stopPropagation();
	});
	
	/*	Click off the menu to close it.	*/
	pageCanvas.off("click").on("click",function(e) {
		if (_root.hasClass("menuOpen") && 
			!$(e.target).closest(".mainMenu").length) {
			// if the menu is currently open
			// AND if they did NOT click inside the menu
			_root.removeClass("menuOpen");
			e.preventDefault();
		}
	});
	
	
	/*======================================================
	** 			|     BOOTSTRAPPY MENU       |  		  **
	** Add classes to the CMS menu to make it bootstrapp-y.
	** @author Jon Brockett.
	** @cite Whitestown/js/site.js.
	======================================================== */
	var mainMenu = $('.mainMenu__menu');
	mainMenu.find('.menu-pulldown').removeClass('menu-pulldown');
	mainMenu.find('ul.level1').addClass('nav navbar-nav');
	mainMenu.find('ul li.hasSubmenu').addClass('dropdown');
	mainMenu.find('ul li.hasSubmenu > a').addClass('dropdown-toggle');
	mainMenu.find('ul li.level2 a').removeClass('dropdown-toggle');
	mainMenu.find('ul.level2').addClass('dropdown-menu');
	mainMenu.find('ul.level2').css('display', '');
	mainMenu.find('li.dropdown a.dropdown-toggle').append('<span class="caret"></span>');
	mainMenu.find('.dropdown-toggle').attr('data-toggle', 'dropdown').attr('role', 'button').attr('aria-haspopup', 'true').attr('aria-expanded', 'false');
	mainMenu.find('li').unbind("mouseenter mouseleave");
})(jQuery);