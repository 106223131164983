var MyPath = {
	profile: {
		update: function() {}
	},
	events: {
		getByDest: function(destID,pathID) {
			/**
			 * ajax call to see if a particular destination has any cms events associated with it
			 * @author Michael Born
			 * @date 12/20/2017
			*/
			return $.ajax({
				url: '/mypath/api/events.cfm',
				type: 'POST',
				data: {action: 'getEventsByDest',destid:destID,pathID:pathID}
			});
		},
		getByDate: function(destID,destDate) {
			/**
			 * ajax call to see if a particular destination has any cms events associated with it within a week of date X.
			 * @author Michael Born
			 * @date 12/20/2017
			*/
			return $.ajax({
				url: '/mypath/api/events.cfm',
				type: 'POST',
				data: {action: 'getEventsByDate', destid:destID, date:destDate}
			});
		}
	},
	paths: {
		getDestsByPath: function(pathID) {
			/**
			 * ajax call to retrieve all destinations from a path
			 * 
			 * @param {string} pathID - the unique id of the path to retrieve
			 * @author Michael Born
			 * @date 1/9/2018
			*/
			return $.ajax({
				url: '/mypath/api/paths.cfm',
				type: 'GET',
				data: {action: 'getDestsByPath',pathID:pathID}
			});
		},
		create: function() {},
		addDest: function(thePathID,theDestID,theDate) {
			/**
			 * ajax call to add a destination visit date to a given path
			 * 
			 * @author Michael Born
			 * @date 12/20/2017
			*/
			return $.ajax({
				url: '/mypath/api/dests.cfm',
				type: 'POST',
				data: {action: 'addDestToPath',pathid:thePathID,destid:theDestID,addDestDate:theDate}
			});
		}
	},
	dests: {
		getByCounty: function(county) {
			return $.ajax({
				url: '/mypath/api/dests.cfm',
				type: 'GET',
				data: {action: 'getDestsByCounty',county:county}
			});
		},
		markFavorite: function(destid,isFavorite) {
			/**
			 * ajax call to make this a favorited destination.
			 * @author Michael Born
			 * @date 12/20/2017
			*/
			return $.ajax({
				url: '/mypath/api/dests.cfm',
				type: 'POST',
				data: {action: 'markFavorite',destid:destid,isFavorite:isFavorite}
			});
		}
	},
	popups: {
		dests: {/*

			changeDate: function(destid) {
				function showIt() {
					panel = $("#changeDate.modal");
					panel.find('.destName').html(destName);
					panel.find('.destID').val(destid);
					
					// I'd like to use type="date", but Chrome is stupid - https://caniuse.com/#feat=input-datetime
					panel.find('#addDestToPathDate').datepicker();
					
					panel.modal("show");
				}
				if (!$("#addToPath.modal").length) {
					// if the popup has been used once, it's probably in the code, so we shouldn't load it twice.
					$.ajax({
						url: '/mypath/api/popups.cfm',
						type: 'GET',
						data: { action: "addDestToPath"}
					})
					.done(function(data) {
						$("body").append(data);
						$("body").find('#addDestToPathDate').datepicker()
						showIt();
					})
					.fail(logAjaxErr);
				} else {
					// else the popup has already been shown once...
					showIt();
				}
				return false;
			},
*/
			addToPath: function(destid,destName) {
				window.destid=destid;
				window.destName=destName;
				
				function showIt() {
					panel = $("#addToPath.modal");
					panel.find('.destName').html(destName);
					panel.find('.destID').val(destid);
					
					// I'd like to use type="date", but Chrome is stupid - https://caniuse.com/#feat=input-datetime
					panel.find('#addDestToPathDate').datepicker();
					
					panel.modal("show");
				}
				
				if (!$("#addToPath.modal").length) {
					// if the popup has been used once, it's probably in the code, so we shouldn't load it twice.
					$.ajax({
						url: '/mypath/api/popups.cfm',
						type: 'GET',
						data: { action: "addDestToPath"}
					})
					.done(function(data) {
						$("body").append(data);
						showIt();
					})
					.fail(logAjaxErr);
				} else {
					// else the popup has already been shown once...
					showIt();
				}
				return false;
			}
		}
	}
};