(function($) {
	var backLink = $(".goBack");
	if (history.length > 1) {//if there IS a history, then go back in time when the user clicks.
		backLink.on("click", function(e) {
			history.go(-1);
			e.preventDefault();
		});
	}//otherwise follow href
})(jQuery);
/*
	
	OLD JUNK.
	Note, however, that this will all probably be necessary
		for the integration between destinations and paths.
	
	
var addToFavorites = function(pathdestid,thisLink) {
	var isFavorite = $(thisLink).hasClass('active') ? 0 : 1;
	//ajax call to make this a favorited destination.
	$.ajax({
		url: '/destinations/addDestToPath.cfm',
		type: 'POST',
		data: {action: 'markFavorite',pathdestid:pathdestid,isFavorite:isFavorite}
	}).complete(function(data) {
		$(thisLink).toggleClass('active');
	});
	return false;
};
var openAddDestPopup = function(destid,destName) {
	var panel = $('#merchantsPanel');
	window.destid=destid;
	window.destName=destName;
	$.ajax({
		url: '/mypath/api/dests.cfm',
		type: 'POST',
		data: {action: 'addDestToPath',destid:destid}
	}).complete(function(data) {
		panel.find('.box-content').html(data.responseText);
		panel.find('.destName').html(destName);
		panel.find('.destID').val(destid);
		$('#addDestToPathDate').datepicker();
		$('#merchantsPanel').fadeIn();
	});
	return false;
};
var submitAddToPath = function() {
	var form = $('#addToPathForm'),
		destID = form.find('.destID').val(),
		addDestDate = $('#addDestToPathDate').val(),
		pathID = $('#choosePathID').val();
	
	if (pathID=="") {
		alert("Please choose a path.");
		return false;
	}
	
	$.ajax({
		url: '/mypath/ajax/dests.cfm',
		type: 'POST',
		data: {action: 'addDestToPath',pathid:pathID,destid:destID,addDestDate:addDestDate}
	})
	.done(function(data) {
		var msg;
		try {
			var json = $.parseJSON(data);
			msg = '<div class="alert alert-'+json.status+'">'+json.message+'</div>';
		} catch(e) {
			// bad JSON?
			msg = data;
		}
		
		form.find('.showOnSubmit').html(msg).slideDown();
		form.find('.hideOnSubmit').slideUp();
	})
	.fail(function(data) {
		alert(data);
		console.log("ajax error");
	});
	return false;
};
var addToPath = function(destid,pathid,addDestDate) {
	$.ajax({
		url: '/mypath/ajax/dests.cfm',
		type: 'POST',
		data: {action: 'addDestToPath',destid:destid,pathid:pathid,addDestDate:addDestDate}
	})
	.done(function(data) {
		
	})
	.fail(function(data) {
		alert(data);
		console.log("ajax error");
	});
	return false;
};
var closePanel = function(callback) {
	$('#merchantsPanel').fadeOut();
	if (typeof callback === "function") {
		callback();
	}
	return false;
};
var checkLoginForm = function(loginForm) {
    if (validate(loginForm)) {
    	$.ajax({
    		url: '/manage/login.cfm',
    		type: 'POST',
    		data: loginForm.find('input').serialize(),
    		success: function(data) {
    			try {
    				var json = $.parseJSON(data);
    				if (json.status == "success") {
    					openAddDestPopup(window.destid,window.destName);
    				} else {
    					loginForm.find('.errorMsg').html('<div class="alert alert-danger">'+json.message+'</div>');
    				}
    			} catch (err) {
    				loginForm.find('.errorMsg').html('<div class="alert alert-danger">'+data+'</div>');
    			}
    		},
    		fail: function(data) {
    			loginForm.find('.errorMsg').html(data);
    			console.log("ajax error");
    		}
    	});
    }
    return false;
};
var validate = function(form) {
    var isValid = true;
    form.find('input.req').each(function() {
    	if ($(this).val()=='') {
    		isValid=false;
    	}
    });
    form.find('.errorMsg').html('<div class="alert alert-danger">Please enter your username and password</div>.');
    return isValid;
};
*/