var getRecommendations = function() {
	var feedDiv = $("#recommendations__feed");
	if (!feedDiv.length) {
		return false;
	}
	var data = {
		date: curdatestring()
	};

	checkGeo = checkSupportsGeo();
	if (checkGeo.supports) {
		//console.log("SUPPORTS GEO!", checkGeo.supports);
		if (checkGeo.geo) {
			//console.log("HERE IS GEO!", checkGeo.geo);
			data.userlat = checkGeo.geo.geolat;
			data.userlng = checkGeo.geo.geolng;
		}
	}
	//console.log(data);


	$.ajax({
		url: "/recfeed/ajax.cfm",
		type: "GET",
		data: data,
		success: function(data) {
			feedDiv.html(data);
		}
	});
};
getRecommendations();