(function($) {
	if (typeof $.fn.owlCarousel !== "function") {
		console.error("jQuery owlCarousel not loaded.");return false;
	}
	var carousel = $(".owl-carousel");
	if (carousel.length) {
		// if there is actually a carousel div...
		$(".owl-carousel").owlCarousel({
			items: 1,
			nav: true
		});
	}
})(jQuery);