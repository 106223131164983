HeaderStuff = function(selector) {
	function construct() {
		var self = this;
		
		this.header = jQuery("#header");
		
		this.headerHeight = this.header.height();
		
		this.scrollClass = "header--scrolled";
		
		this.scrollTolerance = 100;
		
		this.scrolledMoreThanTolerance = function() {
			return Math.abs(window.scrollY - self.storedScroll) >= self.scrollTolerance;
		};
		
		this.isScrolling = function() {
			return window.scrollY !== Header.storedScroll ?  true : false;
		};
		
		this.scrollingDown = function() {
			this.header.addClass(self.scrollClass);
		};
		
		this.scrollingUp = function() {
			this.header.removeClass(self.scrollClass);
		};
		
		this.checkHeaderScroll = function() {
			// if device is smaller than desktop.
			//if (opts.mq.matches) {
				if (self.isScrolling() && self.scrolledMoreThanTolerance()) {
					// make sure user is scrolling down, 
					if (window.scrollY > self.storedScroll && window.scrollY > self.headerHeight) {
						//if current scrollTop is much greater than last scrollTop, then is scrolling in a downward direction
						self.scrollingDown();
					} else {
						self.scrollingUp();
					}
				}
			//}
			self.storedScroll = window.scrollY;
		};
		return this;
	}
	return new construct();
};
(function($) {
	Header = HeaderStuff("#header");
	setInterval(Header.checkHeaderScroll, 300);
})(jQuery);