var getGeo = function(callback) {
	if (typeof newMap === "undefined") { throw "Map not created."; }
	var storageKey = "user-geo",
		geo,
		parsedGeo;

	function ifSuccess(position) {
		parsedGeo = {geolat:position.coords.latitude, geolng:position.coords.longitude};
		save(parsedGeo,false);
		callback(parsedGeo,false);
	}
	function ifError(err) {
		console.log("error while attempting to get geolocation info:",err.code,err);
		// we purposely do NOT save if there is an error,
		// just in case they decide to re-enable location.
		//save(false,err);
		callback(false,err);//pass false to callback saying we had a problem.
	}
	function save(geo,err) {
		var dat = {
			geo: false,
			err: false
		};

		if (geo) { dat.geo = {}; }
		if (err) { dat.err = {}; }

		for (var item in geo) {//save geolocation object
			dat.geo[item]=geo[item];
		}
		for (var itemX in err) {//save error object
			dat.err[itemX]=err[itemX];
		}

		//console.log("saving error to localStorage:",myErr,JSON.stringify(myErr));
		window.localStorage.setItem(storageKey,JSON.stringify(dat));
	}

	geo = window.localStorage.getItem(storageKey);
	//console.log("about to call getCurrentPosition:",geo);

	if (!geo) {//if not saved in local storage
		// Now we actually watch the device location and update the map any time it changes
		navigator.geolocation.watchPosition(ifSuccess,ifError);
	} else {
		geo = JSON.parse(geo);
		if (geo.err) {// uh-oh, error!
			callback(false,geo.err);
		} else if(geo.geo) {//we already have geo
			callback(geo.geo,false);
		} else {//should never reach this clause
			callback(false,false);
		}
	}
};

var GPSMe = function() {
	var self = {
	// get geo support and information if it's saved in local storage.
	checkGeo: checkSupportsGeo(),
	
	// key of user geo in localStorage.
	storageKey: "user-geo",
	
	
	save: function(geo,err) {
		var dat = {
			geo: false,
			err: false
		};

		if (geo) { dat.geo = {}; }
		if (err) { dat.err = {}; }

		for (var item in geo) {//save geolocation object
			dat.geo[item]=geo[item];
		}
		for (var itemX in err) {//save error object
			dat.err[itemX]=err[itemX];
		}

		//console.log("saving error to localStorage:",myErr,JSON.stringify(myErr));
		window.localStorage.setItem(self.storageKey,JSON.stringify(dat));
	},
	startPosition: function() {
		if (self.checkGeo.supports) {
				self.toggleBtnState(true);
			
			if( !self.checkGeo.geo) {
				// then the user did not click the "Show location" button yet,
				// and we should wait until they do.
			} else {
				//console.log("HERE IS GEO!", checkGeo.geo);
				
				// add to map
				self.addToMap(self.checkGeo.geo);
			}
		}
	},
	addToMap: function(geo) {
		newMap.userLoc = L.latLng(geo.geolat, geo.geolng);

		var myLocationIcon = L.icon({
			iconUrl: "/assets/images/google-maps-marker-blue.png",
			iconSize: [30, 48] // same as image size!
		});

	  newMap.userMarker = L.marker(newMap.userLoc, {
		    icon: myLocationIcon,
		    title: "You Are Here"
		});
	
		var myLocationPopup = '<p><strong>Your Location</strong><p>Latitude: ' + parseFloat(geo.geolat).toFixed(5) + '<br> Longitude: ' + parseFloat(geo.geolng).toFixed(5) + '</p>';
	
		newMap.userMarker.bindPopup(myLocationPopup);
	
		// center on the user's location
		//newMap.map.setCenter(waldoIsHere);
	
		// zoom in on the user
		//newMap.map.setZoom(12);
	
		// add the user location marker and make it bounce!
		newMap.userMarker.addTo(newMap.map);
	},
	toggle: function() {
		if (localStorage.getItem("geo-enabled") == "yes") {
			// disable GPS!
			self.toggleBtnState(false);
			self.disable();
		} else {
			// else enable GPS!
			self.enable();
			self.toggleBtnState(true);
		}
	},
	toggleBtnState: function(turnitOn) {
		if (turnitOn) {
			// has already set geo!
			self.btn.classList.replace('mylocation--active','mylocation--inactive');
			self.btnIcon.classList.replace('gps__icon--off','gps__icon--on');
		} else {
			self.btn.classList.replace('mylocation--inactive','mylocation--active');
			self.btnIcon.classList.replace('gps__icon--on','gps__icon--off');
		}
	},
	enable: function() {
		localStorage.setItem("geo-enabled","yes");
		
		getGeo(function(geo,err) {
			callbackDiv = document.getElementsByClassName("gps__err")[0];
	        if (err) {
		        callbackDiv.classList.add("geo-callback--decline");
		        callbackDiv.innerHTML = err.message;
		        console.log("Error:",err);
		        return false;
		    }
	        if (geo) {
	        	self.addToMap(geo);
	        }
		});
	},
	disable: function() {
		localStorage.setItem("geo-enabled","no");
		
		// remove the user marker from the map
		if (typeof window.newMap === "object" &&
			typeof newMap.userMarker === "object") {
			newMap.userMarker.setMap(null);
		}
	
		// actually clear their location!
		localStorage.removeItem(storageKey);
	
		//centerMapOnBounds();
	},
	init: function() {
		self.btn = document.getElementById("gpsBtn");
		self.btnIcon = self.btn.querySelector(".gps__icon");
		
		// setup click event to toggle the user's location
		self.btn.addEventListener("click", self.toggle);
		
		
		// Show user's position on map IF they've already enabled that.
		self.startPosition();
		
		return this;
	}
	};
	// prep for construct.
	return self.init();
};