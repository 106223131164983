var backToTop = '.back-to-top';
var mediaQuerySize = '768px';


// media query event handler
if (matchMedia) {
  var mq = window.matchMedia('(min-width: ' + mediaQuerySize + ')');

  mq.addListener(WidthChange);
  WidthChange(mq);
}

// media query change
function WidthChange(mq) {
  if (mq.matches) {
    // hide backToTop first
  	$(backToTop).hide();

  	// fade in backToTop
  	$(function () {
  		$(window).scroll(function () {
  			if ($(this).scrollTop() > 100) {
  				$(backToTop).fadeIn();
  			} else {
  				$(backToTop).fadeOut();
  			}
  		});

  		// scroll body to 0px on click
  		$(backToTop + ' a').click(function () {
  			scrollToPX(0);
  			return false;
  		});
  	});
  } else {
    // window width is less than mediaQuerySize
    $(backToTop).hide();
  }
}
