(function($) {
	var subscribeBar = $("#subscribeBar"),
		popoutBtn = $("#btnToggleMailingList");
		//var cookieName = "cookie_subscribe",
		//expireDays = 1;
	
	/*
		REMOVE user's ability to hide the subscribe bar.

		// check to see if they closed the featured fact bar.
		if (typeof Cookies === "function") {
			if (Cookies.get(cookieName) &&
				Cookies.getJSON(cookieName).closed) {
				// then close the bar!
				subscribeBar.removeClass("in");
			}
		}
	*/
	
	// when they close the newsletter bar, REMEMBER that it should stay closed!
	subscribeBar.find(".subscribe__close").on("click", function() {
		// if it's a popup, just remove the "popout" class.
		if ( subscribeBar.hasClass("popout") ) {
			subscribeBar.removeClass("popout");
		} else {
			// else close the subscribe bar totally
			subscribeBar.slideUp();
			
			// wait for the slideup to finish before hiding it totally.
			setTimeout(function() {
				subscribeBar.removeClass("in");
			}, 300);
		}
/*
	REMOVE user's ability to hide the subscribe bar.
		if (typeof Cookies === "function") {
			// store "they closed newsletter bar!" in a cookie.
			Cookies.set(cookieName, {closed: true} , { expires: expireDays});
		}
*/
	});
	
	function toggleMailingListPopup() {
		subscribeBar.addClass("popout");
	}
	
	popoutBtn.on("click",toggleMailingListPopup);
	
})(jQuery);