var scrollToPX = function(scrollAmount,timeInMS) {
	if (!timeInMS || timeInMS == "") { timeInMS = 800; }
	
	$('body,html').animate({
		scrollTop: scrollAmount
	}, timeInMS);
};
var logAjaxErr = function(data) {
	switch(data.status) {
		case "401":

		break;
		default:

		break;
	}
	errDialog(data.responseText);
};
var errDialog = function(data) {
	alert(data);
	console.log("Error:",data);
};
var prependAlert = function(divToPrependAlert,alertType,message) {
	divToPrependAlert.find('> .alert-'+alertType).remove();

	//on success
	divToPrependAlert.prepend('<div class="alert alert-'+alertType+' closeOnExit">'+message+'</div>');
};


var checkSupportsGeo = function() {
	var val = {
		supports: true,
		message: "",
		geo: false
	};
	if (typeof window.localStorage !== "object") {
		val = {
			supports: false,
			message: "Local Storage not supported"
		};
	}
	if (typeof navigator.geolocation !== "object") {
		val = {
			supports: false,
			message: "Geolocation is either not supported or is disabled"
		};
	}
	if (val.supports) {
		geo = localStorage.getItem("user-geo");
		if (geo) {
			val.geo = JSON.parse(geo).geo;
		}
	}

	return val;
};
curdatestring = function() {
	var d = new Date(),
		x = '';
	x += d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();

	return x;
};
var toggleItem = function(opts) {
	var tog = {
		options: {
			toggleBtn: $(opts.toggleBtn),
			toggleDiv: $(opts.toggleDiv), //final result = $("#searchDrop")
			onShow: opts.onShow || false,
			onHide: opts.onHide || false
		}
	};
	tog.init = function() {
		var self = this;
		$(self.options.toggleBtn).on("click", self.toggleIt);
		return this; //new instance!
	};
	tog.toggleIt = function(e) {
		var self = tog;
		//show if aria-hidden says yes, else hide.
		if (self.options.toggleDiv.is(":visible")) {
			self.hideIt();
		} else {
			tog.showIt();
		}
		e.preventDefault();
	};
	tog.showIt = function() { //console.log("showIt() called!",arguments);
		var self = tog;
		self.options.toggleBtn.attr("aria-pressed", "true");
		self.options.toggleDiv.fadeIn().attr("aria-hidden", "false");
		if (typeof self.options.onShow == "function") {
			self.options.onShow();
		}
	};
	tog.hideIt = function() { //console.log("hideIt() called!",arguments);
		var self = tog;
		self.options.toggleBtn.attr("aria-pressed", "false");
		self.options.toggleDiv.fadeOut().attr("aria-hidden", "true");
		if (typeof self.options.onHide == "function") {
			self.options.onHide();
		}
	};

	return tog.init(); //construct an object.
};
var getURLParameterByName = function(name) {
	name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results = regex.exec(location.search);
	return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};
var formIsValid = function(form) {
	var boolFormValid = true,
		$custom = $('#Learn_About_MV_Path'),
		$otherBox = $('#otherHide');
	$otherBox.find('.errorMsg').remove();

	if ($custom.val() == 'Other' && !$otherBox.find('input').val()) {
		boolFormValid = false;
		$otherBox.prepend('<div style="padding: 0pt 0.7em;" class="ui-state-error ui-corner-all errorMsg"><span style="float: left; margin-right: 3px;" class="ui-icon ui-icon-alert"></span>The required field(s) below have not been filled in</div>');
	}


	CMScheck = checkRequired(form);

	if (CMScheck && boolFormValid) {
		return true;
	} else {
		if (CMScheck) {
			alert('One or more required items were not filled in.  Please review the form and fill in the identified items.');
		}
		return false;
	}
};
