function YelpAPI() {
	this.businesses = {
		search: function( data, callback ) {
			var url = '/api/yelp/businesses/search';

			return $.get( url, data, callback );
		}
	};
}

var getPlaces = function(lat,lon) {
	var myLoc 	= [lat, lon];
	var Yelp = new YelpAPI();
	var restaurantDiv = $("#restaurantsFill");
	var shoppingDiv = $("#shoppingFill");
	var hotelDiv = $("#lodgingFill");

	var searchOpts = {
		categories: 'restaurants',
		latitude: lat,
		longitude: lon,
		radius: 5000, //1000 METERS = 1 kilometer.
		limit: 10
	};
	var sortResults = function( results ) {
		restaurantDiv.html( printPlaceList(results.businesses) );
	};
	Yelp.businesses.search(searchOpts, sortResults);

	// search for shopping!
	sortResults = function( results ) {
		shoppingDiv.html( printPlaceList(results.businesses) );
	};
	searchOpts.categories = 'shopping';
	Yelp.businesses.search(searchOpts, sortResults);

	// search for hotels!
	sortResults = function( results ) {
		hotelDiv.html( printPlaceList(results.businesses) );
	};
	searchOpts.categories = 'hotels';
	Yelp.businesses.search(searchOpts, sortResults);

	var printPlaceList = function(results) {
		var str = '',
			template = '<li class="place"><a href="{url}" target="_blank">{name}</a></li>',
			maxResults = 10;
			loopTo = results.length < maxResults ? results.length : maxResults;
		if ( results.length === 0 ) {
			console.warn("No results!",results);
			return '<p class="alert alert-warning">No results found.</p>';
		}
		for (var i = 0; i < loopTo; i++) {
			str += template.replace(/\{name\}/gi,results[i].name);
			str = str.replace(/\{url\}/gi,results[i].url);
		}
		return str;
	};

};
(function($) {
	var divsToFill = $("#restaurantsFill,#shoppingFill,#lodgingFill");
	if (divsToFill.length) {
		if (typeof mapAddresses === "object" && mapAddresses.length) {
			getPlaces(mapAddresses[0].lat,mapAddresses[0].lng);
		}
	}
})(jQuery);