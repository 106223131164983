/*

var loadMap = function() {
	if (typeof mapAddresses === "object") {//array of objects
		window.newMap = googleMap('map-canvas', mapAddresses,getMapOptions());
	}
};
*/
function eventListen(items,eventtype,callback) {
	for(var i = 0; i < items.length; i++) {
		items[i].addEventListener(eventtype, callback);
	}
}
function centerMapOnBounds() {
	// assumes that newMap is custom map helper class.
	// assumes that newMap.map is a google map object
	// assumes that newMap has bounds property
	newMap.map.fitBounds(newMap.bounds);
}/*
	UNUSED JUNK
function centerThisPoint(point) {
	// center the map on the turn and zoom in.
	newMap.map.setCenter({lat: point.lat, lng: point.lng});
	newMap.map.setZoom(18);
}
function mapThisTurn(turnPoint) {
	centerThisPoint(turnPoint);

	// add a marker to that exact spot.
	new google.maps.Marker({
		position: turnPoint,
		map:newMap.map,
		title:turnPoint.description
	});
}
*/
var startFirstLastRoutePoints = function() {
	var numTrackPoints,
		lastTrackPoint,
		n;
	if (typeof trackPoints === "object") {
		numTrackPoints = trackPoints.length;
		lastTrackPoint = trackPoints[numTrackPoints - 1];
		bikeRoute = googleRoute(trackPoints,newMap.map);

		newMap.startPoint = {"lat": trackPoints[0].lat, "lng": trackPoints[0].lng};
		newMap.endPoint = {"lat": trackPoints[numTrackPoints - 1].lat, "lng": trackPoints[numTrackPoints - 1].lng};

		// add info windows for start and end points.
		newMap.startPointWindow = bikeRoute.addStartPointMarker(newMap.startPoint);
		newMap.startPointWindow.bindPopup('<strong>Bike Path Begins</strong>');
		newMap.endPointWindow = bikeRoute.addEndPointMarker(newMap.endPoint);
		newMap.endPointWindow.bindPopup('<strong>Bike Path Ends</strong>');
	}
};
function makeItStick() {
	var $sidebar   = $(".searchWidget"),
			$window    = $(window),
			$footer    = $("footer"),
			offset     = $sidebar.offset(),
			foffset	   = $footer.offset(),
			threshold  = foffset.top - $sidebar.height() - offset.top;
			topPadding = 25;
	$sidebar.css("will-change","margin-top");
	window.stickyTimer = setInterval(function () {
/*
		if ($window.scrollTop() > threshold) {
            $sidebar.stop().animate({
                marginTop: threshold
            });
        } else
*/ if ($window.scrollTop() > offset.top) {
            $sidebar.stop().animate({
                marginTop: $window.scrollTop() - offset.top + topPadding
            });
        } else {
            $sidebar.stop().animate({
                marginTop: 0
        	});
		}
	}, 50);
}
var sticky = function(sidebar, container,debug) {
	function Sticky() {
		// Settings
		this.disableAtWidth = 1025;
		this.tolerance = parseInt(100);
		this.debug = debug === true ? true : false;

		// Elements
		this.cont = $(container);
		this.sb = $(sidebar);

		this.sb.addClass("sticky");

		if (this.cont.length == 0 || this.sb.length == 0) {return false;}//No sidebar or container div!

		// Member functions
		this.calcWidth = function(el) {
			return parseInt(el.width() + parseInt(el.css("padding-left")) + parseInt(el.css("padding-right")));
		};
		this.calcHeight = function(el) {
			return parseInt(el.height() + parseInt(el.css("padding-top")) + parseInt(el.css("padding-bottom")));
		};
		this.destroy = function() {
			this.mq.removeListener(this.beginIfDesktop);
		};
		this.disable = function() {
			this.log("disabling scroll event, unsticking sidebar!");
			this.sb.removeClass("fixed bottom").css({
				"position":'',
				"width":''
			});
			$(window).off("scroll");
			return true;
		};
		this.beginIfDesktop = function(mq) {
			//console.log(mq);
			var self = this;
			self.log("mq:",mq);
			self.log("self.contCoords:",self.contCoords);

			//make sure theese height values are up to date.
			this.sbHeight = this.calcHeight(this.sb);
			this.contHeight = this.calcHeight(this.cont);

			if (mq.matches) {//if the window is desktop or larger, then enable sticky sidebar
				if (self.contHeight > self.sbHeight + self.tolerance) {//only run on pages with a taller container than sidebar.
					if (self.sbHeight < $(window).height()) {
						self.sb.css("width",self.sbWidth);
						self.log("Running the sticky sidebar code!");
						self.runScrollHandler();
					} else {
						self.log("Exiting, because sidebar is taller than window.");
					}
				} else {
					self.log("Exiting, because sidebar and container are same height.");
					return false;
				}
			} else {//too small, time to destruct th
				self.disable();
			}
		};
		this.getCoords = function(item) {
			var thisHeight;
			
			//make sure theese height values are up to date.
			this.sbHeight = this.calcHeight(this.sb);
			this.contHeight = this.calcHeight(this.cont);

			if (item == "sidebar") {
				thisHeight = this.sbHeight;
				item = this.sb;
			} else {
				thisHeight = this.contHeight;
				item = this.cont;
			}
			var self = this, thisPos = item.offset();

			var sbPos = {//sidebar position coords
				top: Math.round(thisPos.top),
				bottom: Math.round($(document).height() - (thisPos.top + thisHeight))
				//,
				//left: Math.round(sb.position().left),
				//right: Math.round(( $(document).width() - sbPosition.left + sb.width()) )
			};
			return sbPos;
		};
		this.runScrollHandler = function() {
			//console.log("runScrollHandler() has been called!");
			var self = this;
			$(window).on("scroll.sticky", function(e) {

				if ($("html").hasClass("loggedin")) {//is logged in - don't run!
					self.disable();self.destroy();
					return;
				}

				//update when we scroll. Why?
				//Because Chrome finishes executing the JS before the images have even loaded,
				//which means that self.contCoords is inaccurate, since it doesn't take into account the height of the iamges
				//which are loaded above the container div.
				self.contCoords = self.getCoords('container');
				self.log("contCoords",self.contCoords);
				self.log("contHeight",self.contHeight);

				var scTop = $(window).scrollTop(), sbCoords = self.getCoords('sidebar');

				self.log("sbCoords",sbCoords);
				self.log("sbHeight",self.sbHeight);

				if (sbCoords.top <= self.contCoords.top + self.tolerance) {
					self.log("//then we're at the top!");
					if (scTop > sbCoords.top) {//if we've scrolled BELOW sidebar, then fix to top.
						self.sb.addClass("fixed").removeClass("bottom");
					} else if (scTop == sbCoords.top) {
						self.sb.removeClass("fixed bottom");
					}
				} else {//else we're in the middle???
					self.log("//else we're NOT at the top!");
					//THEN make the sidebar FIXED at the top.
					if (scTop < sbCoords.top) {
						self.sb.addClass("fixed").removeClass("bottom");
					} else {
						if (sbCoords.bottom <= self.contCoords.bottom) {
							self.sb.addClass("bottom");
						}
					}
				}
			}).trigger("scroll.sticky");//end scroll() handler
		};
		this.log = function(text,obj) {
			if (this.debug) {
				if (typeof obj == "undefined") {
					console.log(text);
				} else {
					console.log(text,obj);
				}
			}
		};



		// Initial calculations
		this.sbWidth = this.calcWidth(this.sb);
		this.sbHeight = this.calcHeight(this.sb);
		this.contHeight = this.calcHeight(this.cont);
		this.contCoords = this.getCoords('container');

		// Setup the destroy functionality for if the screen is too small.
		this.mq = window.matchMedia('screen and (min-width: ' + this.disableAtWidth + 'px)');
		this.beginIfDesktop(this.mq);
		var mqChangeEvent = this.beginIfDesktop.bind(this);
		this.mq.addListener(mqChangeEvent);
	}

	return new Sticky();
};
// var DirectionsPopup = function() {
// 	/**
// 	 * Houses the directions popup for the bike paths
// 	*/
// 	var self = this;
	
	
// 	// the DOM element we're working with.
// 	this.div = $("#googMapImage");
	
// 	// we need this in order to show the popup.
// 	this.turnPoint = {
// 		description	: "",
// 		lat 		: "",
// 		lng 		: ""
// 	};
	
// /*
// 	Useful just to see what it should look like.
// 	this.dirPopupHTML = '<div id="googMapImage" class="bikepath__map fade">
// 		<div class="bikepath__map__inner">
// 			<div class="bikepath__map__content">
// 				<button class="bikepath__map__close btn btn-reset" onclick="dirPopup.hide()" aria-controls="googMapImage"><span class="fa fa-close"></span><span class="sr-only">Close directions map</span></button>
// 				<h2 class="bikepath__map__title"></h2>
// 				<div class="bikepath__map__img"></div>
				
// 				<!-- note: unused!
// 				<div class="bikepath__map__navbar">
// 					<button class="btn btn-link" onclick=""><span class="fa fa-chevron-left"></span> Previous</button><button class="btn btn-link"><span class="fa fa-chevron-right"></span> Next</button>
// 				</div> -->
// 			</div><!--//.bikepath__map__content-->
// 		</div><!--//.bikepath__map__inner-->
// 	</div>';
// */
	
// 	// array of ALL turn points!
// 	this.turnPointArr = [];
	
// 	this.load = function(turnPoint) {
// 		/**
// 		 * load()
// 		 * set up the directions popup.
// 		 * 
// 		 * @author Michael Born
// 		 * @date 1/9/2018
// 		 */
// 		self.turnPoint = turnPoint;
		
// 		self.setImage();
		
// 		self.setTitle();
// 	};
// 	this.setImage = function() {
// 		/**
// 		 * setImage()
// 		 * grab a google maps static image url and put it in the popup as an <img> tag.
// 		 * 
// 		 * @author Michael Born
// 		 * @date 1/9/2018
// 		 */
// 		self.mapSrc = self.turnPoint.mapImg;
		
// 		// load a static google maps image of the turn / direction point.
// 		self.div.find(".bikepath__map__img").empty().append('<img src="' + self.mapSrc + '" alt="' + self.turnPoint.description + '" />');
// 	};
// 	this.setTitle = function() {
// 		/**
// 		 * setTitle()
// 		 * grab the direction turn point description and put it in the <h3> in the popup.
// 		 * 
// 		 * @author Michael Born
// 		 * @date 1/9/2018
// 		 */
// 		// set the direction title
// 		self.div.find(".bikepath__map__title").html(self.turnPoint.description);
// 	};
// 	this.toggle = function() {
// 		/**
// 		 * toggle()
// 		 * show or hide, depending on whether it's already shown or hidden.
// 		 * 
// 		 * @author Michael Born
// 		 * @date 1/9/2018
// 		*/
// 		if (self.div.hasClass("in")) {
// 			// then hide it!
// 			self.hide();
// 		} else {
// 			// else show it!
// 			self.show();
// 		}
// 	};
// 	this.hide = function() {
// 		/**
// 		 * hide()
// 		 * Hide the popup. Called by the close button.
// 		 * 
// 		 * @author Michael Born
// 		 * @date 1/9/2018
// 		*/
// 		self.div.removeClass("in");
// 		setTimeout(function() {self.div.hide();}, 300);
// 	};
// 	this.show = function() {
// 		/**
// 		 * show()
// 		 * duh.
// 		 * 
// 		 * @author Michael Born
// 		 * @date 1/9/2018
// 		*/
// 		// open it
// 		self.div.show();
		
// 		// fade in after 300 milliseconds gives the map image time to load. very impressive.
// 		setTimeout(function() {self.div.addClass("in");}, 300);
// 	};
	
// 	return this;
// };

// dirPopup = new DirectionsPopup();

function doBikePathMapStuff() {
	if (typeof mapAddresses === "object") {
		
		// DIABLE turn-by-turn directions
		// // enable turn-by-turn directions
		// if (typeof directionPoints === "object") {
		// 	var turnPoints = document.getElementsByClassName("directions__turnpoint");
		// 	eventListen(turnPoints,"click", function(e) {
		// 		// set it up
		// 		dirPopup.load(directionPoints[this.getAttribute("data-turnpoint")]);
				
		// 		// now show it
		// 		dirPopup.show();
		// 	});
		// 	eventListen(turnPoints,"keydown", function(e) {
		// 		keyPressed = e.keycode || e.which;
		// 		switch(keyPressed) {
		// 			case 13:
		// 				// set it up
		// 				dirPopup.load(directionPoints[this.getAttribute("data-turnpoint")]);
						
		// 				// now show it
		// 				dirPopup.show();
		// 			break;
		// 		}
		// 	});
		// }
		if (typeof trackPoints === "object") {
			startFirstLastRoutePoints();
		}
	}
}

(function($) {
	var theMap = document.getElementById("map-canvas");
	if (theMap) {
		theMap.addEventListener("mapLoaded", doBikePathMapStuff);
	}
	
	// Sidebar should stick when scrolling
	if (typeof jQuery === "function" && typeof $.fn === "object") {
		var sidebar = sticky('.stickySideBar','#contLeft');
	}
})(jQuery);