var googleRoute = function(routePoints,map) {
	/*
	 * Route(array)
	 * this class handles all stuff related to ROUTES (otherwise known as TRAILS) on a map.
	 * @date 05/09/2017
	 * @author Michael Born
	 */
	var self = this;
	
	this.map = map;
	
	this.routePoints = routePoints;
	
	this.initialize = function() {
		/*
		 * Route.initialize(array,map)
		 * @param {Array} routePoints - an array of {lat,lng} points which will be used to draw the line.
		 * @returns {boolean} success - true for now.
		 * @date 05/09/2017
		 * @author Michael Born
		 */
		//console.log("initialize(): ",self.routePoints,self.map);
		self.path = L.polyline(self.routePoints,{
			weight: 5,
			color: "#FF0000"
		}, self.map).addTo(self.map);

		// make the polyline bounds extend the map bounds
		self.map.fitBounds( self.path.getBounds() );
		
		return this;
	};
	this.addStartPointMarker = function(point) {
		/* 
		 * @Route.addStartPointMarker(point)
		 * @brief this function is used to create a google.maps.Marker marker to signify the beginning of the route. It uses a custom icon to visually set it apart from the other map markers.
		 * @param {Object} point - a single {lat,lng} point which is used to add the marker
		 * @returns {boolean} success - true for now.
		 * @date 05/09/2017
		 * @author Michael Born
		*/
		var startIcon = L.icon({
			iconUrl: "/bikepaths/google-maps-green-marker.png",
			iconSize: [30, 40]
		});
		return L.marker(point, {icon: startIcon}).addTo(self.map);
	};
	this.addEndPointMarker = function(point) {
		/* 
		 * @Route.addEndPointMarker(point)
		 * @brief this function is used to create a google.maps.Marker marker to signify the END of the route. It uses a custom icon to visually set it apart from the other map markers, including the route beginning marker.
		 * @param {Object} endPoint - a single {lat,lng} point which is used to add the marker
		 * @returns {boolean} success - true for now.
		 * @date 05/09/2017
		 * @author Michael Born
		*/
		var endIcon = L.icon({
			iconUrl: "/assets/images/google-maps-marker-flag.png",
			iconSize: [30, 40]
		});
		return L.marker(point, {icon: endIcon}).addTo(self.map);
	};
	return this.initialize(arguments);
};

var showOnMap = function(mapPinID) {
	var scrollToVert = $("#map-canvas").position().top-50;
	window.newMap.showInfoWindow(mapPinID);
	window.scrollTo(0,scrollToVert);
};
var handlePinClick = function(e) {
	var clickedPinID = $(e.target).attr("data-pinid");//console.info("clickedPinID:",clickedPinID);
	e.preventDefault();
	showOnMap(clickedPinID);
};
var getMapOptions = function() {
	var customMapOpts = {
		infoWindow: {
			open: false,
			template: '<p><strong>{title}</strong><br/>{teaser}</p><p><a href="{url}" class="button button-primary button-small">View Details</a><a href="https://maps.google.com/maps?saddr=current+location&daddr={address}" class="button button-secondary button-small" target="_blank">Directions</a></p>'
		},//don't open the info windows if we have more than one.
		zoomLevel: 10
	};
	return customMapOpts;
};
var loadMap = function(addresses) {//console.info("_maps.js:loadMap()",arguments);		
	// in case of re-init, we need to clear the old map.
	if ( typeof L === "object" ) {
		var container = L.DomUtil.get( 'map-canvas' );
		if(container != null){
			container._leaflet_id = null;
		}

		window.newMap = MapWrap('map-canvas', addresses,{
			infoWindow: {
				template: '<p><strong>{title}</strong><br/>{address}</p><p><a href="{url}" style="display:inline-block;padding:4px 8px;background:#142f55;color:#fff;margin-right: 10px;">View Details</a><a href="https://maps.google.com/maps?saddr=current+location&daddr={address}" target="_blank" style="display:inline-block;padding:4px 8px;background:#ccc;color:#222;margin-right: 10px;">Directions</a></p>'
			}
		});
		
		$("a.showOnMap").on("click", handlePinClick);
	}
};
window.onload = function() {
	
	/*
	 * see _gpsme.js for this stuff.
	 * Once the map is loaded, we load the gps location of the user.
	*/
	var mapDiv = document.getElementById("map-canvas");
	if (mapDiv) {
		mapDiv.addEventListener("mapLoaded", function() {
			myLocation = new GPSMe();
		});
	}
	
	/**
	 * now actually start the map.
	*/
	if (typeof mapAddresses === "object" && mapAddresses.length) {
		//array of addresses
		window.setTimeout(function() {loadMap(mapAddresses);},800);
	} else {
		// just show a blank map centered on New Hartford.
		loadMap([],{});
	}
};