var brochureFormEvents = function() {

	$('#brochureForm').find('#Learn_About_MV_Path').on('change', function() {
		if ($(this).val() == 'Other') {
			$('#otherHide').fadeIn();
		} else {
			$('#otherHide').fadeOut();
		}
	}).trigger('change');

	$('#brochureForm').find('#Other').on('click', function() {
		if ($("#Other").is(":checked")) {
			$('#otherHide2').fadeIn();
		} else {
			$("#otherHide2").fadeOut();
		}
	}).trigger('click');
	//window.setTimeout(function() {imgCrop()},1000);
};
brochureFormEvents();